import React, { useContext } from 'react'
import { InfoWindow } from '@react-google-maps/api'
import UserContext from 'core/contexts/userContext'
import styled from 'styled-components'
import { Button } from 'antd'
import PinInfor from './PinInfor'
import { useNavigate, useLocation } from 'react-router-dom'
import { checkColorStatusBg, checkColorStatusText, checkStatusText } from './container'
export default function InfoDetailPc({ data, id, coordinates, setIdInfor, idInfor, conversFullDate }) {
  const navigator = useNavigate()
  const location = useLocation()
  console.log('location', location?.pathname.split('/')[1])

  const { meData } = useContext(UserContext)

  const handleInfoWindowClick = (id, condinate) => {
    setIdInfor(prevId => (prevId === id ? null : id))
  }

  const handleInfoWindowBlur = () => {
    setIdInfor('')
  }

  let statusColor = data?.status_data?.length ? data?.status_data[data?.status_data?.length - 1]?.name : ''
  let checkOfficer = Array.isArray(data?.department_data)
    ? data?.department_data?.map(item => item?.officer_data?.id)?.filter(id => id !== undefined)
    : [data?.department_data]?.map(item => item?.officer_data?.id)?.filter(id => id !== undefined)
  let areAllStrings = checkOfficer?.every(item => typeof item === 'string')
  let checkBtnUpdateWork = data?.status_data[data?.status_data?.length - 1]?.name !== 'done' && areAllStrings

  const checkType = () => {
    if (data?.media_url) {
      let type = data?.media_url[0]?.split('.')
      if (type[type?.length - 1] === 'mp4') {
        return <video className="video-complaint" src={data?.media_url[0]} controls />
      } else if (
        type[type?.length - 1] === 'jpg' ||
        type[type?.length - 1] === 'png' ||
        type[type?.length - 1] === 'jpeg'
      ) {
        return (
          <img
            className="img-complaint"
            src={data?.media_url[0]}
            onError={e => {
              // e.currentTarget.src = data?.category?.icon_url
              e.target.setAttribute('src', data?.category?.bg_icon_line)
            }}
          />
        )
      }
    }
  }

  return (
    <div className="box-info-detail">
      <InfoWindow
        position={coordinates}
        options={{
          pixelOffset: new window.google.maps.Size(-10, 20),
        }}
        zIndex={2}
      >
        <div onClick={() => handleInfoWindowClick(id, coordinates)} onBlur={handleInfoWindowBlur}>
          <PinInfor
            idInfor={idInfor}
            id={id}
            img={
              id === 'outside'
                ? 'https://gateway.koratcity.go.th/sit/media/images/3eb0eab6-2ed5-4429-9724-61c06a25f01a.png'
                : data?.category?.icon_url
            }
            status={statusColor}
          />
        </div>
      </InfoWindow>
      {id !== 'outside' && idInfor === id ? (
        <InfoWindow
          position={coordinates}
          options={{
            pixelOffset: new window.google.maps.Size(-250, 120),
          }}
          zIndex={2}
        >
          <BoxInfor>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: '16px', fontWeight: '500', color: '#150F0B' }}>
                <img src={data?.category?.icon_url} className="icon-complate" />
                {data?.category?.name}
              </p>
              <TextStatus
                bg={
                  data?.status_data?.length
                    ? checkColorStatusBg(data?.status_data[data?.status_data?.length - 1]?.name)
                    : ''
                }
                text={
                  data?.status_data?.length
                    ? checkColorStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
                    : ''
                }
              >
                {data?.status_data?.length
                  ? data?.status_data[data?.status_data?.length - 1]?.name
                    ? checkStatusText(data?.status_data[data?.status_data?.length - 1]?.name)
                    : '-'
                  : '-'}
              </TextStatus>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr' }}>
                <div>
                  <TextStyle>ผู้ร้องเรียน</TextStyle>
                  <p>
                    {data?.name?.first_name && data?.name?.last_name
                      ? `${data?.name?.first_name} ${data?.name?.last_name}`
                      : '-'}
                  </p>
                </div>
                <div>
                  <TextStyle>ประเภทเรื่องร้องเรียน</TextStyle>
                  <p> {data?.category?.name ? data?.category?.name : '-'}</p>
                </div>
                <div>
                  <TextStyle>ประเภทย่อย</TextStyle>
                  <p>{data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}</p>
                </div>
              </div>
              <div>
                {data?.media_url?.length ? (
                  checkType()
                ) : (
                  <img className="img-complaint" src={data?.category?.bg_icon_line} />
                )}
              </div>
            </div>
            <hr></hr>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <div>
                <TextStyle>เลขที่เรื่องร้องเรียน</TextStyle>
                <p>{data?.complaint_no ? data?.complaint_no : '-'}</p>
              </div>
              <div>
                <TextStyle>เวลาและวันที่</TextStyle>
                <p>{conversFullDate(data?.created_at)}</p>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              <Button onClick={() => setIdInfor('')} className="btn-close">
                ปิด
              </Button>
              {checkBtnUpdateWork && meData?.position_data?.id !== '1730459515121299456' && (
                <Button
                  className="btn-update"
                  onClick={() => {
                    navigator(`/${location?.pathname.split('/')[1]}/bof-overview-complaint/view-update-status`, {
                      state: {
                        record: data?.id,
                      },
                    })
                  }}
                >
                  อัปเดตผลการดำเนินการ
                </Button>
              )}
            </div>
          </BoxInfor>
        </InfoWindow>
      ) : (
        ''
      )}
    </div>
  )
}

const BoxInfor = styled.div`
  width: 395px;
  height: auto;

  @media screen and (max-width: 900px) {
    width: auto;
    /* width: 320px; */
    margin: 10px;
    font-size: 10px;
    padding: 15px;
  }

  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  font-family: 'Prompt';
  .icon-complate {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .img-complaint {
    border-radius: 10px;
    width: 144px;
    height: 82px;
    margin: 0 10px;
    @media screen and (max-width: 900px) {
      max-width: 100px;
    }
  }
  .video-complaint {
    border-radius: 10px;
    width: 144px;
    height: 82px;
    margin: 0 10px;

    @media screen and (max-width: 900px) {
      max-width: 100px;
    }
  }
  .btn-close.ant-btn {
    box-shadow: none !important;
    cursor: pointer;
    color: #f36b24 !important;
    border: 1px solid #f36b24;
    background: #fff;
    width: 80px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
  }
  .btn-update.ant-btn {
    box-shadow: none !important;
    cursor: pointer;
    color: #fff !important;
    border: 1px solid #f36b24;
    background: #f36b24;
    width: 150px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
  }
  hr {
    border: 1px solid #ffeee7;
  }
`

const TextStyle = styled.div`
  color: #c4c4c4;
  font-size: 10px;
  font-weight: 400;
`
const TextStatus = styled.div`
  width: auto;
  height: 24px;
  background: ${props => (props?.bg ? props?.bg : '')};
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  color: ${props => (props?.text ? props?.text : '')};
`
